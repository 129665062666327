/**
 * Utils/Text.
 */
.text-xsmall,
.text-xs {
  font-size: 10px !important;
}

.btn.btn-small {
  min-width: initial;
}

.text-small,
.text-sm {
  font-size: 12px !important;
}

.btn-xs,
.btn-sm {
  font-size: 10px !important;
}

.text-md {
  font-size: 14px;
}

.text-lg {
  font-size: 16px;
}

.text-xl {
  font-size: 18px;
}

.text-xxl {
  font-size: 20px;
}

.text-primary {
  color: #497fdc;
}

.text-warning {
  color: #f0ad4e;
}

.l-h-input {
  line-height: 50px !important; /* same as .form-control height */
}

/**
 * Utils/Colors.
 */

.text-rc {
  color: #bd1a1f;
}

.text-info.text-lt {
  color: #749de4;
}

a {
  color: #4972d8;
}

.link-default,
.link-default:focus,
.link-default:hover {
  color: inherit;
}

.link-danger {
  color: #be1922;
}

/**
 * Utils/Tables
 */

.table.table-wiz {
  border-color: #fff;
  border-width: 0;
  margin-bottom: 0;
}

.table.table-wiz.table-striped > tbody > tr:nth-child(odd) {
  background-color: #f8f9fd;
}

.table > thead > tr > th,
.table > tbody > tr > th,
.table > tfoot > tr > th,
.table > thead > tr > td,
.table > tbody > tr > td,
.table > tfoot > tr > td {
  font-weight: normal;
}

.table.table-wiz > thead > tr > th,
.table.table-wiz > tbody > tr > th,
.table.table-wiz > tfoot > tr > th,
.table.table-wiz > thead > tr > td,
.table.table-wiz > tbody > tr > td,
.table.table-wiz > tfoot > tr > td {
  border-color: #fff;
  font-size: 80%;
  padding: 6px 12px;
}

.table.table-wiz > thead > tr > th,
.table.table-wiz > tbody > tr > th,
.table.table-wiz > tfoot > tr > th {
  background-color: #577ddb;
  border-color: #91ABE8;
  color: #fafafa;
  padding: 6px 30px 6px 12px;
  text-transform: uppercase;
  white-space: nowrap;
}

a:focus,
a:hover {
  color: #2d5cd2;
}

a.btn-link-success:hover,
a.text-success:hover,
a.btn-link-success:hover .wizicon,
button.btn-link-success:hover {
  color: #449d44;
  fill: #449d44
}

.vertical-separator-right {
  border-right: 1px solid #e4eaec;
}

.vertical-separator-left {
  border-left: 1px solid #e4eaec;
}

#message {
  height: 30px;
  width: 200px;
  margin-left: auto;
  margin-right: auto;
}

a {
  cursor: pointer;
}

/**
 * Utils/Btn.
 */

.btn {
    -webkit-box-shadow: none;
  box-shadow: none;
  font-size: 11px;
  line-height: 1.5em;
  min-width: 115px;
}

.btn:active,
.btn.active {
    -webkit-box-shadow: none;
  box-shadow: none;
}

.btn-group .btn {
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
  border-radius: 2px;
  margin-right: 7px;
  margin-bottom: 5px;
}

.modal-header .btn {
  margin-top: -4px;
}

.js-publication-settings-choice .panel {
  position: relative;
}

.js-publication-settings-choice .panel .panel-heading {
  padding: 0;
}

.js-publication-settings-choice .panel .panel-heading > a {
  border: 1px solid #777777;
  border-radius: 4px;
  color: #777777;
  display: block;
  padding: 15px;
}

.js-publication-settings-choice .panel.in .panel-heading > a,
.js-publication-settings-choice .panel .panel-heading > a:hover {
  color: #497FDC;
  border-color: #497FDC;
}

.js-publication-settings-choice .panel .panel-body {
  padding: 15px 0;
}

.js-publication-settings-choice .panel .switch-button {
  margin-left: 0; /* Forced to do this because of .form-group .switch-button */
}

.js-publication-settings-choice .panel .checked-item {
  display: none;
  font-size: 9px;
}

.js-publication-settings-choice .choice-icon {
  display: block;
  font-size: 24px;
  line-height: 24px; /* Fix for wizicons */
  margin-bottom: 10px;
}

.js-publication-settings-choice .choice-label {
  display: block;
  font-size: 12px;
}

.js-publication-settings-choice .choice-text {
  font-size: 13px;
  height: 50px; /* = .choice-icon height + .choice-label height | works in combination with .hbox and .col.col-middle */
}

.js-publication-settings-choice .panel .checked-item .fa-stack-2x {
  color: #497FDC;
}

.js-publication-settings-choice .panel .checked-item .fa-stack-1x {
  color: #fff;
  line-height: 2em; /* Don't know why it's 2.4em, it must work for 14px * 2.4em but does not scale correctly at smaller sizes */
}

.js-publication-settings-choice .panel.in .checked-item {
  display: block;
  position: absolute;
  top: 0;
  right: -1px; /* wizicons is rebellious */
}

.btn.btn-xs {
  border-color: transparent;
  min-width: 0;
  padding: 3px 6px;
}

.btn-default {
  background-color: #fff;
}

.btn-default:hover {
  background-color: #eee;
  color: #444;
}

.btn-wizdefault {
  background-color: #f6f7fd;
  border-color: #e4eaec;
  color: #497FDC;
}

.btn-wizdefault:active,
.btn-wizdefault:focus,
.btn-wizdefault:hover {
  background-color: #F2F3F9;
  color: #2d5cd2;
}

.btn:before {
  display: inline-block;
  font-family: FontAwesome;
  font-size: 1.1em;
  line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  padding-right: 0.5em;
  text-rendering: auto;
  vertical-align: middle;
}

.btn.btn-icon {
  position: relative;
  padding-left: 55px;
  /* border-width: 0; */
  padding-right: 18px;
}

.btn.btn-icon.btn-sm {
  min-width: 0;
  padding-left: 45px;
  padding-right: 10px;
}

.btn.btn-icon [class*="fa fa-"] {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  display: inline-block;
  width: 40px;
  border-right: 1px solid #f7f7f7;
  padding: 0 10px;
  line-height: 1.75em;
}

.btn.btn-icon.btn-sm [class*="fa fa-"] {
  font-size: 12px;
  line-height: 2.1em;
  width: 34px;
}

.disabled-icon {
  color: #9e9e9e;
}

.btn-trash:before {
  content: "\f1f8"; /* .fa.fa-trash */
}

.social-box {
  background-color: #f6f7fd;
  border: 1px solid #e4e9ec;
  display: block;
  margin-bottom: 30px;
  text-align: center;
}

.social-box-img {
  display: block;
  margin: 0 auto 10px;
}

.social-box-title, .social-box-img {
  display: block;
}

.social-box-title a {
  color: #333;
}

.social-box a:hover {
  text-decoration: underline;
}

.social-box-btn {
  color: #333;
}

.social-box .wizicon {
  height: 50px;
  width: 50px;
}

.social-box-btn-facebook:hover {
  background-color: #3c5a99;
}

.social-box-btn-facebook:hover path.inside-svg-2 {
  fill: #3c5a99;
}

.social-box-btn-twitter:hover {
  background-color: #1da1f2;
}

.social-box-btn-linkedin:hover {
  background-color: #00669e;
}

.social-box-btn-linkedin:hover path.inside-svg-2 {
  fill: #00669e;
}

.social-box-btn-yammer:hover {
  background-color: #0072c6;
}

.social-box-btn:hover {
  color: #fff;
}

.social-box-btn:hover path {
  fill: #fff;
}

.fa-spin {
  top: -1px !important;
  animation: none;
}

.fa-spinner:before {
    -webkit-animation: fa-spin 2s infinite linear;
    -moz-animation: fa-spin 2s infinite linear;
    -o-animation: fa-spin 2s infinite linear;
    -ms-animation: fa-spin 2s infinite linear;
  animation: fa-spin 2s infinite linear;
    -webkit-transform-origin: 50% 50%;
    -moz-transform-origin: 50% 50%;
    -o-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
  display: inline-block;
}

.btn-lg {
  padding: 10px 40px;
  font-size: 14px;
  margin: 0 10px;
}

.timelines-contact .timeline > div > .btn {
  margin-left: 20px;
}

.contact-content .btn {
  float: right;
}

.btn-slim {
  min-width: 0;
  padding: 6px 9px;
}

.btn-close {
  background: transparent;
  border: solid thin #c0c0c0;
  color: #c0c0c0;
}

.btn-close:hover {
  border-color: #626262;
  color: #626262;
}

.btn-min {
  min-width: 0;
  background-color: white;
  border-radius: 17px;
  margin-right: 4px;
  border: none;
  padding: 6px 14px;
}

.btn-min-primary {
  color: #497FDC;
  border-color: #497FDC;
  background-color: rgba(31, 84, 197, 0.04);
}

.btn-min-primary:hover {
  color: #2d6bd7;
}

.btn-min-danger:hover .fa-trash {
  color: rgba(255, 0, 0, 0.78) !important;
}

.btn-min-danger {
  color: #BE1922;
  border-color: #BE1922;
  background-color: rgba(190, 25, 34, 0.07);
}

.btn-primary-outline {
  border-color: #497fdc;
  color: #497fdc !important;
  background-color: #fff;
  padding: 5px 12px;
}

.btn-primary-outline:hover {
  border-color: #497fdc;
  color: #497fdc !important;
  background-color: #fff;
}

.btn.btn-primary-outline.btn-icon [class*="fa fa-"] {
  border-right: 1px solid #2d6bd7;
  line-height: 1.65em;
}

.btn-success-outline,
.btn-success-outline:hover {
  border-color: #4ac13a;
  color: #4ac13a;
  background-color: #fff;
}

.btn-danger-outline,
.btn-danger-outline:hover {
  border-color: #be1922;
  color: #be1922;
  background-color: #fff;
}

.btn-denied-block-outline,
.btn-denied-block-outline:hover {
  border-color: #82858c;
  color: #82858c;
  background-color: #fff;
}

/**
 * Utils/Icons
 */

.fa-stack-checked {
  height: 1em;
  line-height: 1em;
  width: 1em;
}

.fa-stack-checked .fa-stack-2x {
  font-size: 1em;
}

.fa-stack-checked .fa-stack-1x {
  bottom: -0.8em;
  left: auto;
  font-size: 0.8em;
  right: -1.2em;
}

.fa-bg-blue {
  color: #fff;
  background-color: #497fdc;
  border-radius: 3px;
  padding: 3px 5px;
}

.fa-bg-success {
  color: #fff;
  background-color: #4ac13a;
  border-radius: 3px;
  padding: 3px 5px;
}

.btn-danger .fa-trash {
  color: #ffffff;
}

.btn-primary .wizicon,
.btn-primary .wizicon path,
.btn-success .wizicon,
.btn-warning .wizicon,
.btn-danger .wizicon {
  fill: #fff;
}

.header .fa-paper-plane-o {
  color: #7C7F86;
  font-size: 28px;
}

.wizicon {
  height: 15px;
  width: 15px;
}

a svg.fa-lg {
  height: 21px;
  width: 21px;
}

a .wizicon {
  fill: #2d5cd2;
}

a.text-success .wizicon path {
  fill: #6ccf5f;
}

a.disabled .wizicon {
  fill: #9e9e9e;
}

.btn-link > .wizicon {
  float: left;
}

.link-danger .wizicon,
.btn-link-danger .wizicon {
  fill: #be1922;
}

/**
 * Utils/Lists.
 */

.inline-block {
  display: inline-block;
}

.file-upload img {
  margin-bottom: 5px;
}

.modal .close {
  font-size: 12px;
  padding: 6px 12px;
}

/* a few small third party css files put here to reduce http file requests */
/* jquery.easy-pie-chart.css */
.easyPieChart {
  position: relative;
  text-align: center;
  display: inline-block;
}

.easyPieChart canvas {
  position: absolute;
  top: 0;
  left: 0;
}

.box-stats {
  text-align: center;
}

.infobox-progress {
  width: 100%;
}

.infobox-data {
  margin-top: 10px;
  display: inline-block;
}

.fa-color-green.fa-stack-1x.comment-go {
  line-height: 2em;
}

#review-workflow {
  height: 800px;
  overflow-x: auto;
}

#emailing-review-button-only {
  height: 100px;
}

.card-bordered.green-review-message {
  background-color: #eef9ef;
}

.card-bordered.red-review-message {
  background-color: #f2dede;
}

.card-bordered.grey-review-message {
  background-color: #f6f7fd;
}

.card-bordered,
.section--bordered {
  background-color: #fff;
  border: 1px solid #e4eaec;
  border-radius: 4px;
  box-shadow: none;
  word-break: break-word;
  min-height: 20px;
  padding: 30px;
  padding-bottom: 30px;
  padding-top: 30px;
  margin-bottom: 20px;
}

.card-bordered .btn-xs {
  padding: 5px 6px 3px;
}

.well {
  background-color: #fff;
  border: 1px solid #e4eaec;
  border-radius: 4px;
  box-shadow: none;
  word-break: break-all;
}

/*
 * Badges
 */

.badge.badge-success {
  background-color: #5cb85c;
  color: #fff;
}

.badge.badge-warning {
  background-color: #f0ad4e;
  color: #fff;
}

.badge.badge-danger {
  background-color: #d9534f;
  color: #fff;
}

.fa-clickable {
  cursor: pointer;
}

/* label */
/*.label {
  font-weight: normal;
}*/

.label-default {
  background-color: #7c7f85;
  color: #f6f7fd;
}

.label--light {
  background-color: #fff;
  color: #7d8085;
  padding-left: 20px;
  padding-right: 20px;
  text-transform: none;
}

#message {
  height: 30px;
  width: 200px;
  margin-left: auto;
  margin-right: auto;
}

/*For Tags Manager */
/*#tag-details {
  float: left;
  border: 1px solid #ddd;
  overflow-y: auto;
  padding-top: 20px;
}*/

.ignore-alert {
  /*height: 55px;*/
}

h1, h2, h3, h4, h5, h6 {
  color: #373737;
}

/* formulaire */

.form-compact .form-group {
  margin-bottom: 15px;
}

.form-compact.well {
  background: #f6f7fd;
}

form .editor .editor-header {
  border: #e4eaec solid 1px;
  border-bottom: none;
}

form .editor .editor-header a {
  border-right: #e4eaec solid 1px;
  padding: 10px 15px;
  display: inline-block;
  color: #7c7f85;
}

form .editor textarea {
  resize: none;
  height: 200px;
}

form .editor .editor-footer {
  border: #e4eaec solid 1px;
  border-top: none;
}

form .editor .editor-footer button {
  border: 1px solid transparent;
  border-left: #e4eaec solid 1px;
  padding: 10px 15px;
  display: inline-block;
  float: right;
  cursor: pointer;
  background: transparent;
  display: inline-block;
  font-weight: normal;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
  user-select: none;
}

form .editor .editor-footer span {
  padding: 10px 15px;
  display: inline-block;
}

.btn-composed-icons,
.btn-composed-icons-large {
  display: block;
}

.btn-composed-icons > .btn,
.btn-composed-icons-large > .btn {
  border-radius: 0;
  font-size: 11px !important; /* backward compatibility makes !important is required */
  min-width: 95px; /* backward compatibility for .btn min-width */
}

.btn-composed-icons > .btn:first-child,
.btn-composed-icons-large > .btn:first-child {
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}

.btn-composed-icons > .btn:last-child,
.btn-composed-icons-large > .btn:last-child {
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}

.btn-composed-icons .btn:active,
.btn-composed-icons .btn:hover,
.btn-composed-icons .btn.active,
.btn-composed-icons-large .btn:active,
.btn-composed-icons-large .btn:hover,
.btn-composed-icons-large .btn.active {
  background-color: #fff;
  color: #497fdc;
  border: 1px solid #497fdc;
}

.btn-composed-icons .fa,
.btn-composed-icons-large .fa,
.btn-composed-icons .wizicon,
.btn-composed-icons-large .wizicon {
  color: #d1d1d1;
  fill: #d1d1d1;
}

.btn-composed-icons-large .fa,
.btn-composed-icons-large .wizicon {
  display: block;
  margin: auto;
  margin-bottom: 7px;
}

.btn-composed-icons-large .wizicon {
  height: 60px;
  margin-top: 7px;
  width: 60px;
}

.btn-composed-icons-large .fa {
  color: #d1d1d1;
  display: block;
  font-size: 2em;
  line-height: 2em;
}

.btn-composed-icons > .btn:active .wizicon,
.btn-composed-icons > .btn:hover .wizicon,
.btn-composed-icons > .btn.active .wizicon,
.btn-composed-icons-large > .btn:active .wizicon,
.btn-composed-icons-large > .btn:hover .wizicon,
.btn-composed-icons-large > .btn.active .wizicon {
  fill: #497fdc;
}

.btn-composed-icons > .btn:active .fa,
.btn-composed-icons > .btn:hover .fa,
.btn-composed-icons > .btn.active .fa,
.btn-composed-icons-large > .btn:active .fa,
.btn-composed-icons-large > .btn:hover .fa,
.btn-composed-icons-large > .btn.active .fa {
  color: #497fdc;
}

.btn-composed-icons .active::after,
.btn-composed-icons-large .active::after {
  position: absolute;
  content: "\e091";
  font-family: wizicons;
  font-style: normal;
  font-weight: normal;
  line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  top: 0;
  right: -2px;
}

.btn-composed-icons-large .active::after {
  font-size: 1.5em;
  top: -1px;
}

@media screen and (max-width: 767px) {
  .btn-composed-icons-large .btn:not(:first-child):not(:last-child):not(.dropdown-toggle),
  .btn-composed-icons-large .btn:first-child:not(.dropdown-toggle),
  .btn-composed-icons-large .btn:last-child:not(.dropdown-toggle) {
    border-radius: 3px;
  }
}

.help-inline,
.help-block {
  color: #AAA;
}

/**
 * Header.
 */

.navbar.navbar-default {
  background-color: #f6f7fd;
  border-bottom: #e4e9ec solid 1px;
  margin-bottom: 0;
}

.navbar-inverse {
  background-color: #3d3c3f;
}

.navbar.navbar-inverse .navbar-nav > li > a {
  color: #ddd;
}

.navbar-inverse .navbar-nav > .open > a, .navbar-inverse .navbar-nav > .open > a:hover, .navbar-inverse .navbar-nav > .open > a:focus {
  background-color: #3d3c3f;
}

.navbar-default .navbar-toggle:hover,
.navbar-default .navbar-toggle:focus {
  background-color: #f6f7fd;
}

.navbar-inverse .navbar-toggle {
  color: #fff;
}

.navbar-inverse .navbar-toggle:focus {
  background-color: #3d3c3f;
}

.navbar-inverse .navbar-toggle#leftMenuToggle {
  border-color: #999;
}

.navbar-nav > li > a,
.navbar-default .navbar-nav > .open > a,
.navbar-default .navbar-nav > .open > a:hover,
.navbar-default .navbar-nav > .open > a:focus {
  border-right: #e4e9ec solid 1px;
}

.navbar-nav .dropdown-toggle:last-child {
  border: none;
}

.navbar-nav > li > a {
  line-height: 32px;
  padding: 9px 15px;
}

.navbar-nav .avatar {
  display: inline-block;
  margin-bottom: 0;
  margin-right: 12px;
}

.navbar-nav .avatar > img {
  height: 32px;
  width: auto;
}

.organisation-client {
  border: 1px solid #bd1a1f;
}

.organisation-wiztopic {
  border: 1px solid #E9E9E9;
}

.navbar-default .navbar-nav > .open > a, .navbar-default .navbar-nav > .open > a:hover, .navbar-default .navbar-nav > .open > a:focus {
  color: inherit;
  background-color: transparent;
}

.navbar.navbar-inverse .dropdown-menu {
  border-color: #080808;
  background: #3d3c3f;
}

.navbar.navbar-inverse .dropdown-menu li {
  border-color: #080808;
}

.navbar.navbar-inverse .dropdown-menu li a {
  color: #ddd;
}

.navbar.navbar-inverse .dropdown-menu li a:hover {
  color: #777;
}

.channel-lang-menu {
  max-height: 420px;
  overflow: scroll;
}

@media screen and (max-width: 767px) {
  .table-list .row:first-child li:first-child {
    border-top: 1px solid #e7e7e7;
    border-bottom: 0;
  }

  .table-list .b-pair {
    border-right: 1px solid #e7e7e7;
    border-left: 1px solid #e7e7e7;
  }
}

.pac-container:after {
  border-top: 1px solid #e6e6e6;
  height: 26px;
  padding-top: 5px;
  padding-bottom: 5px;
}

/* Time Line */
.timeline ol > li {
  position: relative;
  border-left: 1px solid #cdd2d2;
  padding-bottom: 20px;
}

.timeline li:last-child:before {
  top: auto;
  bottom: 0;
}

.timeline li:last-child {
  padding-bottom: 0;
}

.timeline li .content {
  padding: 10px;
  background-color: white;
  border-bottom: 1px solid #cdd2d2;
  position: relative;
  margin-top: 10px;
  margin-left: 20px;
  position: relative;
  color: #7c7f85;
}

.timeline li .content strong {
  color: #373737;
  font-weight: normal;
}

.timeline li:last-child .content {
  border: none;
}

.timeline li .icon {
  width: 40px;
  height: 40px;
  text-align: center;
  color: white;
  vertical-align: middle;
  line-height: 40px;
  font-size: 12px;
  position: absolute;
  top: -7px;
  left: -20px;
}

.timeline .timeline-social .social-avatar {
  width: 25px;
  height: 25px;
  text-align: center;
  color: white;
  vertical-align: middle;
  line-height: 40px;
  font-size: 12px;
  position: absolute;
  left: 50px;
  top: 3px;
}

.timeline .timeline-social li .content,
.timeline .timeline-social li .title {
  margin-left: 80px;
}

.timeline .icon.mail .fa-circle,
.timeline .icon.web .fa-circle,
.timeline .icon .fa-circle {
  color: #be1922;
}

.timeline .icon.twitter .fa-circle {
  color: #3ab0ea;
}

.timeline .icon.facebook .fa-circle {
  color: #3b5998;
}

.timeline .icon.j .fa-circle {
  color: #3181d6;
}

.timeline li .title {
  margin-left: 25px;
  padding: 2px 0;
  font-size: 16px;
}

.timeline li .title,
.timeline li .title .text-muted {
  color: #373737;
}

.timeline li .title .date {
  color: #7c7f85;
}

.timeline li .title small {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  display: block;
  font-size: 10px;
}

.timeline li .well {
  background-color: #f6f7fd;
  border: none;
  border-bottom: 1px solid #e4eaec;
  width: 100%;
}

.link-separator a:first-child {
  border-right: 1px solid #777;
  padding-right: 1em;
}

.link-separator a:last-child {
  padding-left: 1em;
}

/** avatar **/

.thumbnail .avatar {
  margin-left: auto;
  max-width: 150px;
}

.contact-list .avatar {
  display: table;
  width: 100%;
}

.avatar .text-content {
  display: table-cell;
  text-align: center;
  vertical-align: middle;
  height: 100%;
  font-size: 75px;
  color: #be1922;
  border: 1px solid #e4e9ec;
  border-radius: 170px;
}

.avatar .text-content.red {
  border-color: #be1922;
}

.personnal-data .avatar {
  float: left;
  margin-right: 20px;
}

@media (min-width: 1200px) {
  .contact-list {
    width: 110px;
  }

  .avatar .text-content {
    font-size: 75px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .contact-list {
    width: 90px;
  }

  .avatar .text-content {
    font-size: 60px;
  }
}

.message {
  padding: 5px 0;
  margin: 10px 0;
}

.message .message-title strong {
  padding-left: 4px;
}

.message .message-title .timeago {
  padding-left: 45px;
  font-style: italic;
  font-size: 10px;
}

.message .message-body {
  padding-left: 46px;
  padding-top: 15px;
}

.message {
  border-bottom: 1px solid #e4e9ec;
}

.no-border-bottom .message:last-child {
  border: none;
}

.main-nav-bg.main-nav-bg-inverse {
  border-right: 1px solid #080808;
  background: #3d3c3f;
}

/**
 * Forms.
 */

.form-binline > [class*="col-"] {
  padding: 0;
  padding-right: 5px;
}

.form-binline > [class*="col-"]:first-child {
  padding-left: 15px;
}

.form-binline > [class*="col-"]:last-child {
  padding-right: 15px;
}

.form-binline label {
  margin: 0;
  line-height: 50px;
}

.form-binline label,
.form-binline .btn.btn-link {
  line-height: 50px;
  padding: 0 7px;
}

.form-group {
  margin-bottom: 20px;
}

.form-control {
  height: 50px;
}

.form-control.input-sm {
  height: 30px;
  padding: 5px 7px;
}

.form-control[disabled] {
  background-color: #f6f7fd;
  color: #7c7f85;
}

.form-group .switch-button {
  margin: 12px auto;
}

.form-group .switch-button-left {
  margin: 0 7px 0;
}

.form-group .switch-button.toggle-inline {
  margin: 12px 0 12px 12px;
}

#wizcropper-previews label {
  opacity: 0.6;
  padding: 0;
}

#wizcropper-previews label.active {
  opacity: 1;
}

/**
 * Image upload (avatar).
 */

.avatar-upload .avatar {
  margin-left: auto;
  max-width: 135px;
}

.avatar-upload .avatar > img {
  width: 100%;
}

.avatar-upload .alpha {
  display: none !important;
}

.avatar-upload .upload-select-button {
  display: block;
  margin: auto;
  max-width: 135px;
}

.cover-upload {
  min-height: 210px;
}

.jcrop-holder {
  margin: auto;
}

/** Contenue **/
.page-header {
  border: 0;
  line-height: 30px;
  margin: 0;
  margin-bottom: 25px;
  padding: 0;
}

.page-header h1,
.page-header .h1,
.page-header h2,
.page-header .h2 {
  border: 0;
  display: inline-block;
  margin-top: 0; /* Should be via .row-space-0 utility but until all pages have been reworked it does the trick. */
  line-height: 30px;
  vertical-align: middle;
}

h1, .h1,
h2, .h2 {
  font-size: 1.3em;
  font-weight: normal;
  padding: 0;
}

.h4 {
  font-size: 1.1em;
}

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
  line-height: 1.25;
}

.page-header .back {
  border-right: 1px solid #e4eaec;
  font-size: 1em;
  color: #4980db;
  float: left;
  margin-right: 20px;
  padding-right: 20px;
}

.page-header .back .fa-angle-left {
  line-height: inherit;
}

.boxs {
  margin-bottom: 40px;
}

.plus-one-count {
  color: #9f9f9f;
  display: block;
  margin-bottom: 5px;
}

.box-quick-link-footer {
  text-transform: none;
}

.box-quick-link {
  border: 1px solid #e4e9ec;
  text-align: center;
  border-radius: 4px;
}

.guest-overview {
  margin-bottom: 20px;
}

.box-quick-link > * {
  text-decoration: none;
}

.box-quick-link .header {
  height: 60px;
  line-height: 60px;
  padding-bottom: 2px;
  background-color: #f6f7fd;
  border-bottom: 1px solid #e4e9ec;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}

.guestbox {
  height: 40px !important;
  line-height: 40px !important;
}

.box-quick-link .header .fa {
  color: white;
  font-size: 25px;
  vertical-align: middle;
  color: #7c7f86;
}

.guestbox .fa {
  font-size: 16px;
}

.box-quick-link .content {
  text-transform: uppercase;
  font-size: 85%;
  white-space: nowrap;
  overflow: hidden;
  -ms-text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  color: #586161;
  padding: 5px;
}

.box-quick-link .content strong {
  color: #bd1a1f;
  font-weight: normal;
  font-size: 25px;
  display: block;
}

.box-quick-link a:hover {
  text-decoration: none;
}

.box-quick-link.box-quick-link-horizontal .header {
  height: auto;
  padding: 10px 25px;
  float: left;
  border-right: 1px solid #e4e9ec;
  border-bottom: none;
  display: inline-block;
  border-top-right-radius: 0;
  border-bottom-left-radius: 4px;
}

.box-quick-link.box-quick-link-horizontal .header .fa {
  font-size: 22px;
}

.box-quick-link .content strong {
  font-size: 30px;
  margin-top: 5px;
}

.box-quick-link .content strong sup {
  top: -6px;
}

.box-quick-link .content small {
  font-size: 12px;
}

.box-quick-link .content p {
  margin-top: 22px
}

.spacer {
  width: 100%;
  height: 1px;
  float: left;
}

.spacer.spacer-10 {
  height: 10px;
}

.spacer.spacer-20 {
  height: 20px;
}

.spacer.spacer-30 {
  height: 30px;
}

.spacer.spacer-40 {
  height: 40px;
}

.spacer.spacer-50 {
  height: 50px;
}

/**** Dashboard *****/
.gaph h4 {
  margin-bottom: 20px;
}


/** panel **/
.panel {
  margin-bottom: 0;
}

.panel-group .panel {
  border-radius: 0;
}

.panel.panel-default,
.panel.panel-full-width {
  border-width: 0;
}

.panel.panel-light {
  background-color: #f7f9fc;
  border-color: #e4eaec;
}

.panel.panel-full-width .panel-title {
  padding: 20px 0;
  border-bottom: 1px solid #e4e9ec;
}

.panel .panel-title > a[data-toggle="collapse"]:first-child {
  color: #373737;
}

.panel .panel-title > a[data-toggle="collapse"]:first-child:before {
  content: "\f07d";
  color: #428bca;
  display: inline-block;
  font-family: wizicons;
  font-size: 12px;
  font-style: normal;
  font-weight: normal;
  line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  margin-right: 5px;
  width: 20px;
}

.panel .panel-title > a[data-toggle="collapse"]:first-child.collapsed:before {
  content: "\f054";
}

.panel .panel-title .panel-title-info {
  display: inline-block;
}

.panel .panel-title .panel-title-info .label {
  -webkit-border-radius: 4px;
     -moz-border-radius: 4px;
          border-radius: 4px;
}

.panel .panel-title .panel-title-info ~ .panel-title-info {
  padding-right: 12px;
}

.panel .panel-title .panel-title-info .edit {
  margin-right: 40px;
  font-size: 20px;
}

.panel.panel-full-width .panel-heading {
  padding: 0;
  border: 0;
}

.panel.panel-light .panel-body {
  border: #e4eaec 1px solid;
}

.panel-full-width .panel-collapse,
.panel-full-width .tab-pane {
  margin: 0 -30px;
}

.panel-full-width .panel-body,
.panel.panel-light .panel-body,
.panel.panel-light.panel-full-width .panel-body,
.contact-content {
  background-color: #f6f7fd;
  border: none;
  border-top: #e4eaec 1px solid;
  border-bottom: #e4eaec 1px solid;
  margin-top: -1px;
  padding: 30px;
}

.panel-full-width .panel-collapse,
.panel-full-width .tab-pane,
.panel.panel-light.panel-full-width .panel-body {
  margin: 0 -30px;
}

.accordion-list .panel-body {
  padding-left: 50px;
}

.panel .panel-body .table > thead > tr > th,
.panel .panel-body .table > tbody > tr > th,
.panel .panel-body .table > tfoot > tr > th,
.panel .panel-body .table > thead > tr > td,
.panel .panel-body .table > tbody > tr > td,
.panel .panel-body .table > tfoot > tr > td {
  border: none;
}

.panel .panel-body .table > thead > tr > td,
.panel .panel-body .table > tbody > tr > td,
.panel .panel-body .table > tfoot > tr > td {
  height: 50px;
}

.panel .panel-body .table > thead > tr > td,
.panel .panel-body .table > tbody > tr > td,
.panel .panel-body .table > tfoot > tr > td {
  color: #373737;
}

.panel .panel-body .table > thead >tr > td.align-middle,
.panel .panel-body .table > tbody >tr > td.align-middle,
.panel .panel-body .table > tfoot >tr > td.align-middle {
  vertical-align: middle;
}

.panel.panel-default.panel-bordered {
  border-width: thin;
}

.panel-heading.height-md {
  height: 40px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.h1.heading-for-panel,
.h2.heading-for-panel,
.h3.heading-for-panel {
  margin-bottom: 0;
  margin-top: 0;
  font-size: 1.2em;
}

.panel-heading.height-md > a {
  line-height: 20px;
}

.panel .panel-body.body-collapse {
  padding: 0 15px;
}

@media screen and (max-width: 767px) {
  .panel .panel-body.body-collapse {
    padding: 0 30px;
  }

}

.panel-body.body-collapse > div {
  margin-bottom: 10px;
  margin-top: 10px;
}

.fa-mail-click {
  font-size: 37px !important;
  line-height: 1.35em;
}

.fa-mail-open {
  font-size: 35px !important;
  line-height: 1.25em;
}

.fa-mail-sent {
  font-size: 38px !important;
  line-height: 0.85em;
}

/*
 * PANELS / PANEL-NEXTGEN
 */

.panel-group.panel-group-alt .panel + .panel {
  margin-top: 0;
}

@media screen and (min-width: 992px) {
  .panel-group.panel-group-2k17 {
    margin-left: -31px; /* compensate #content .container-fluid + border-width */
  }
}

.panel-group-2k17 .panel.panel-2k17 {
  border-top: 1px solid #e4e9ec;
}

.panel-group-2k17 .panel.panel-2k17:first-child {
  border-top: 0;
}

.panel-group-2k17 .panel.panel-2k17 .panel-collapse {
  /*margin-left: 0;  compensate .panel-full-width .panel-collapse */
}

.panel.panel-2k17 {
  border-width: 0;
}

.panel.panel-2k17 .panel-heading {
  border: 0;
  padding: 0;
}

.panel.panel-2k17 .panel-heading .panel-collapse-chevron,
.panel.panel-2k17 .panel-heading .panel-icon-type {
  float: left;
  line-height: 1;
}

.panel.panel-2k17 .panel-heading .panel-icon {
  height: 58px; /* .panel-title.height + .panel-title.border-bottom-width */
  line-height: 1;
  padding: 16px 16px;
  width: 58px; /* .panel-title.height + .panel-title.border-bottom-width */
}

.panel.panel-2k17 .panel-heading .panel-icon > .wizicon {
  height: 22px;
  width: 22px;
}

.panel.panel-2k17 .panel-heading .panel-collapse-chevron {
  background-color: #f6f7fd;
  padding: 20.5px;
  border-right: 1px solid #e4e9ec;
}

.panel.panel-2k17 .panel-heading .panel-collapse-chevron .wizicon {
  fill: #777;
  transition-duration: 0.2s;
  transition-property: transform;
}

/*
 * To ensure support for collapse of collapse of collapse... we're forced to make
 * this selector extra specific like this: `.panel.panel-2k17.in > .panel-heading > a > ...`
 */
.panel.panel-2k17.in > .panel-heading > a > .panel-collapse-chevron .wizicon {
    -o-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

.panel.panel-2k17 .panel-heading .panel-icon-type {
  background-color: #6B9CEF;
  fill: #fff;

}

.panel.panel-2k17 .panel-title {
  padding: 18px 15px;
  overflow: hidden;
  font-size: 15px;
}

.panel.panel-2k17 .panel-title-text {
  min-height: 22px; /* 16px * line-height (1.42...), for empty title */
}

@media screen and (max-width: 767px) {
  .panel.panel-2k17 .panel-title {
    border-top: 1px solid #e4e9ec;
    clear: both;
    padding: 18px;
  }
}

.panel.panel-2k17 .panel-title > a[data-toggle="collapse"]:first-child:before {
  content: none;
}

.panel.panel-2k17 .panel-link {
  float: right;
  line-height: 0; /* Resolve an inline / spacing problem with ul,li */
  margin-bottom: 0;
  vertical-align: top; /* http://stackoverflow.com/questions/19366401/my-inline-block-elements-are-not-lining-up-properly */
}

.panel.panel-2k17 .panel-link > li {
  display: inline-block;
  vertical-align: top;
}

.panel.panel-2k17 .panel-link-item {
  display: inline-block;
  font-size: 0.85em;
  line-height: 22px; /* Same as .panel-title */
  padding: 18px;
  vertical-align: top;
}

.panel.panel-2k17 .panel-link-item .wizicon {
  display: block;
  height: 22px; /* Ensure vertical alignement */
}

.panel.panel-2k17 .panel-link-item.btn {
  border-bottom: 0;
  border-top: 0;
    -moz-border-radius: 0;
    -webkit-border-radius: 0;
  border-radius: 0;
  min-width: auto; /* override the min-width on .btn (backward-compatibility) */
  vertical-align: top; /* Weird bug with 'middle' on .btn, 1px space on top of the btn when inside a .panel-heading */
}

.panel.panel-2k17 .panel-link-item.btn:last-child {
  border-right-width: 0;
}

.panel.panel-2k17 .panel-collapse {
  background-color: #f6f7fd;
  position: relative;
}

.panel.panel-2k17 .panel-collapse .panel-body {
  border-top-color: transparent;
  border-bottom: 1px solid #e4eaec;
  padding: 30px;
  padding-left: 58px;
  /*padding-left: 8px; /* backward compatibility for table inside collapse */
}

.panel-group.panel-group-2k17 .panel-heading + .panel-collapse:before {
  background-color: #e4eaec;
  content: '';
  height: 1px;
  left: 58px; /* .panel-collapse-chevron.width */
  position: absolute;
  right: 0;
  top: 0;
  z-index: 99;
}

.panel-group.panel-group-2k17 .panel-heading + .panel-collapse > .panel-body {
  border-bottom-color: #6B9CEF;
}

.panel.panel-2k17.panel-newsletter .panel-icon-type {
  background-color: #1F4F7F;
}

.panel.panel-2k17.panel-newsletter .panel-icon-secondary {
  fill: #9e9e9e;
}

.panel-group.panel-group-2k17 .panel-newsletter .panel-heading + .panel-collapse > .panel-body {
  border-bottom-color: #1F4F7F;
}


.panel.panel-2k17.panel-pressbook .panel-icon-type {
  background-color: #1F4F7F;
}

.panel.panel-2k17.panel-pressbook .panel-icon-secondary {
  fill: #1F4F7F;
}

.panel-group.panel-group-2k17 .panel-pressbook .panel-heading + .panel-collapse > .panel-body {
  border-bottom-color: #1F4F7F;
}

.btn-link-success .wizicon {
  fill: #6ccf5f;
}

.panel.panel-2k17.panel-pressbook.current-release .panel-icon-type {
  background-color: #6ccf5f;
}

/* expert */

.expert.panel .panel-title {
  line-height: 75px; /* To match the .avatar-width */
  vertical-align: middle;
}

.expert.panel .avatar-width {
  vertical-align: middle;
  width: 75px;
}

.expert.panel .avatar-width .avatar {
  margin: 0;
}

.expert.panel .panel-title > a {
  display: inline-block;
  vertical-align: middle;
}

.expert.panel .panel-body h3 {
  margin-bottom: 40px;
  font-weight: normal;
  font-size: 22px;
}

.expert.panel .panel-body h3.title-cont {
  margin-bottom: 30px;
}

.expert.panel .panel-body h3 span {
  width: 36px;
  height: 36px;
  display: inline-block;
  vertical-align: middle;
}

.expert.panel .panel-body h3 i {
  display: table-cell;
  text-align: center;
  vertical-align: middle;
  border: 1px solid #373737;
  border-radius: 90px;
  float: right;
  padding: 5px 10px;
  font-style: normal;
}

.expert.panel .panel-body h5 {
  margin-bottom: 20px;
}

.expert.panel .panel-body p.block-text {
  font-weight: 300;
  text-align: justify;
  margin-bottom: 20px;
  color: #7c7f85;
}

.expert.panel .panel-body p.date {
  color: #7c7f85;
  font-size: 12px;
  margin-top: -20px;
}

.expert.panel .panel-body .label-default {
  background-color: #7c7f85;
  color: #fff;
  margin-bottom: 10px;
  float: left;
}

.expert.panel .panel-body .row {
  padding-left: -30px;
  padding-right: -30px;
}

.expert.panel .panel-body .row > div {
  padding-bottom: 20px;
  border-bottom: 1px solid #e4e9ec;
  padding-left: 30px;
  padding-right: 30px;
}

.expert.panel .panel-body .row > div:last-child {
  border: none;
}

@media (min-width: 1200px) {
  .expert.panel .panel-body .row > div {
    border: none;
    padding-bottom: 0;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .expert.panel .panel-body .row > div {
    border: none;
    padding-bottom: 0;
  }
}


#selected-target .panel .panel-title,
#tag-details .panel .panel-title {
  margin: 0 0;
  padding: 20px 10px;
}

#selected-target .panel:first-child .panel-title {
  padding-top: 0;
}

#selected-target .panel .panel-body,
#tag-details .panel .panel-body {
  border: #e4eaec 1px solid;
}

/*.select-contact > * {
  max-height: 700px;
  overflow: auto;
}*/

@media screen and (max-width: 767px) {
  #campaign-list {
    display: table;
    width: 100%;
  }

  .topic.panel {
    display: table-row;
    width: 100%;
  }

  .topic.panel .panel-title .panel-title-info,
  .topic.panel .panel-title .panel-title-text {
    display: table-cell;
    float: none !important;
  }

  .topic.panel .panel-cell {
    display: table-cell;
  }

  .topic.panel .panel-title .panel-title-info {
    text-align: center;
  }

  .topic.panel .panel-title .panel-title-text {
    vertical-align: middle;
    width: 100%;
  }

  .panel .panel-body {
    padding: 30px;
  }

  .topic.panel .panel-body th:nth-child(n+2) {
    display: none;
  }

  #selected-target {
    margin-left: 36px;
    margin-top: 10px;
  }

  ul.nav.red-select-list.red-select-list-1.tab-contact-list {
    margin-left: 45px;
  }
}

/** icone **/
.icon-draft {
  color: #90bcec;
}

.icon-ok {
  color: #497fdc;
}

.icon-none {
  display: none;
}

.fa-color-green {
  color: #6ccf5f;
}

.fa-color-red {
  color: #d9534f;
}

.fa-hover-green:hover {
  color: #6ccf5f;
}

/** list contact **/
.contacts-list li {
  list-style-type: none;
}

.contacts-list .avatar-show-liste.active {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.contacts-list .avatar-show-liste.active i.fa-plus-circle:before {
  content: "\f056";
}

.clearfixHeight {
  margin-bottom: 256px;
  float: left;
  width: 100%;
}

.contact-content:after,
.contact-content:before {
  bottom: 100%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
}

.contact-content:after {
  border-bottom-color: #f6f7fd;
  border-width: 15px;
  left: 20%;
  margin-left: -15px;
}

.contact-content:before {
  border-bottom-color: #e4e9ec;
  border-width: 16px;
  left: 20%;
  margin-left: -16px;
}

.contact-content h6,
.contact-content p {
  text-align: center;
}

.contact-content h6 {
  font-size: 20px;
}

.contact-content input {
  margin: 20px 0;
}

.contact-content .icon {
  margin: 0 10px;
}

.contact-content .icon span {
  margin-bottom: 10px;
}

/** topic **/

.review-overview h2 {
  line-height: 1.4;
}

.review-overview .btn-primary {
  border-width: 0;
}

.review-overview .btn-primary .fa-eye,
.review-box .btn-primary .fa-question {
  top: -1px;
}

.review-box .btn-success {
  float: right;
}

.review-box h4 .fa-stack {
  font-size: 20px !important;
}

.review-box .fa-stack.fa-lg .fa-stack-1x {
  line-height: 2em;
}

.review-box h4 {
  font-size: 16px;
}

.highlight-form .btn-icon {
  margin-top: 5px;
}

.highlight-form .form-group .switch-button {
  margin: 0;
}

#topic-preview-modal .close {
  line-height: 42px;
  padding: 0;
}

#topic-preview-modal .modal-lg {
  width: 90%;
  max-width: 1170px;
}

#topic-preview-modal .modal-header {
  padding: 15px 25px;
}

#topic-preview-modal .modal-title {
  font-size: 14px;
}

@media (max-width: 768px) {
  #topic-preview-modal .modal-body {
    padding: 15px 15px;
  }

  .review-box .btn-success {
    float: none;
    margin-bottom: 20px;
  }
}

#topic-preview-modal.night-mode .close {
  color: #eee;
}

#topic-preview-modal.night-mode .modal-header {
  border-color: #2E363F;
}

#topic-preview-modal.night-mode .modal-title {
  color: #aaa;
}

#topic-preview-modal.night-mode .modal-content,
#topic-preview-modal.night-mode .modal-body,
#topic-preview-modal.night-mode .topic-preview .topic-content * {
  background-color: #151e29;
}

.topic-preview .panel-collapse .panel-body {
  padding-left: 45px;
}

.topic-preview .panel-title > a[data-toggle="collapse"]:first-child:before {
  content: none;
}

.topic-preview .panel-title > a[data-toggle="collapse"]:first-child.collapsed:before {
  content: none;
}

.topic-preview .panel-title > a > span {
  margin-right: 10px;
}

.topic-preview .topic-head h1 {
  font-size: 30px;
  font-family: 'Bitter', Georgia, Arial, serif;
  font-weight: 500;
  text-align: left;
}

.topic-preview .topic-head .info {
  font-family: 'Open Sans', "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.topic-preview .topic-head .labels {
  text-align: left;
}

.topic-preview .topic-head,
.topic-preview .topic-content {
  line-height: 1.6em;
  position: relative;
  text-align: justify;
}

.topic-preview .topic-content {
  color: #7b7e83;
  font-family: 'Bitter', Georgia, Arial, serif;
  font-size: 16px;
  line-height: 1.6em;
  overflow-wrap: break-word;
}

.topic-preview .topic-content h2 {
  margin-bottom: 15px;
}

.topic-preview .topic-content h3 {
  color: #7b7e83;
}

.topic-preview .topic-content > p:first-child:first-letter {
  font-size: 50px;
  line-height: 50px;
  color: #373737;
  float: left;
  display: inline-block;
  vertical-align: top;
  margin-right: 10px;
}

.topic-preview .topic-content > .table-responsive > table td,
.topic-preview .topic-content > .table-responsive > table th,
.topic-preview #publication-collapses .panel-body table td,
.topic-preview #publication-collapses .panel-body table th {
  padding: 5px 10px;
}

@media (max-width: 480px) {
  .topic-preview .topic-head,
  .topic-preview.topic-content {
    text-align: left;
  }
}

.topic-preview .topic-head .content-highlight {
  font-size: 18px;
  line-height: 1.35em;
}

.night-mode .topic-preview .topic-head .content-title,
.night-mode .topic-preview .topic-head .content-infos,
.night-mode .topic-preview .topic-head .content-highlight,
.night-mode .topic-preview .topic-content p:first-letter,
.night-mode .topic-preview .topic-content * {
  color: #fff !important;
    -webkit-transition: 1s;
  transition: 1s;
}

/** topic-collapse **/

.panel-topic .panel-heading {
  color: #333;
  padding: 0;
  position: relative;
}

.panel-group .panel-topic.in:first-child {
  border-top: 1px solid #e4e9ec;
}

.panel-group .panel-topic .panel-heading + .panel-collapse > .panel-body {
  border-top-width: 0;
}

.panel-topic .panel-no,
.panel-topic .panel-chevron,
.panel-topic .panel-title {
  font-size: 18px;
  line-height: 1.6em;
}

.panel-topic .panel-title {
  border-bottom: 1px solid #e4e9ec;
  padding: 20px 25px 20px 25px;
}

.panel-group.panel-ordered .panel-topic .panel-title {
  margin-left: 65px;
  padding: 20px 65px 20px 0;
}

@media (max-width: 768px) {
  .panel-group.panel-ordered .panel-topic .panel-title {
    margin-left: 20px;
  }
}

.panel-topic.in .panel-title,
.panel-topic.highlighted .panel-title,
.panel-topic.in .panel-no,
.panel-topic.highlighted .panel-no {
  font-weight: bold;
}

.panel-topic.in .panel-title {
  border-bottom-width: 0;
}

.panel-topic .panel-no,
.panel-topic .panel-chevron {
  padding: 20px 0;
  position: absolute;
  top: 0;
}

.panel-topic .panel-no {
  left: 25px;
}

.panel-topic .panel-chevron {
  right: 25px;
}

.panel-topic .panel-body {
  border-top: 0;
  padding: 25px 25px 25px 25px;
}

.panel-group.panel-ordered .panel-topic .panel-body {
  padding: 25px 65px 25px 65px;
}

.validate-review {
  padding: 10px;
  border-radius: 3px;
  background-color: #eef9ef;
}

.validate-review .check-icon {
  float: left;
  font-size: 18px;
  line-height: 2.3em;
  margin: -2px;
}

.validate-review-inner {
  padding-left: 45px;
}

.validate-review-subline {
  color: #777;
  display: block;
  font-style: italic;
  font-size: 10px;
}

.panel .panel-body {
  position: relative;
}

.highlight {
  position: absolute;
  top: 0;
  left: 80px;
}

.highlight > * {
  display: inline-block;
}

.highlight:before {
  color: #BBBBBB;
  content: '|';
  margin-right: 20px;
}

.panel .move-sheep-links {
  height: 32px; /* Same height as others <li /> */
  position: relative;
  /*width: 38px;*/ /* Same width as others <li /> + 2*5px padding (left and right) */
}

.button-list {
  text-align: right;
}

.button-list button,
.button-list a {
  margin-left: 5px;
}

/** contact **/

.select-contact .mail {
  word-wrap: break-word;
}

.contact .info {
  margin-top: 10px;
  margin-left: 40px;
}

.contact .info p.name {
  font-size: 18px;
  color: #7c7f85;
}

.contact .info p.name strong {
  color: #373737;
  font-weight: 500;
}

.contact .info p {
  font-size: 14px;
  margin-bottom: 15px;
}

.contact .contact-list {
  width: 200px;
}

.contact .text-content {
  padding: 10px 0;
}

.contact #contact-in-list-count {
  font-size: 120px;
}

.label-contact {
  margin-top: 30px;
}

.label-contact .label {
  float: left;
}

.timelines-contact {
  margin-top: 50px;
}

.timelines-contact .timeline li .content {
  margin-top: 0;
  border: none;
}

.timelines-contact ul {
  padding-left: 0;
}

.timelines-contact ul li {
  list-style: none;
}

.relation-contact #activity_type_statecheck {
  vertical-align: middle;
}

.relation-contact #s2id_activity_type_state {
  display: inline-block;
  vertical-align: middle;
}

.relation-contact #s2id_activity_type_state .select2-choice {
  height: 30px;
  padding: 6px 20px;
  line-height: 1.4em;
}

.relation-contact #s2id_activity_type_state .select2-choice .select2-arrow b {
  background-position: 0 -8px;
}

.relation-contact #activities-array .linkedTo-item {
  display: inline-block;
  margin-right: 5px;
  margin-bottom: 5px;
  white-space: nowrap;
}

/* add RS to contact */

.rs-liste .fa-stack .fa-stack-1x {
  color: #fff;
}

.rs-liste .fa-stack .fa-circle {
  opacity: 0.5;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
  filter: alpha(opacity=50);
}

.rs-liste .fa-stack.active .fa-circle {
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  filter: alpha(opacity=100);
}

.red-select-list {
  background-color: #fff;
  z-index: 999;
  /*display: block !important;*/
}

.list-select {
  background-color: #fff;
  border: 1px solid #e4eaec;
  display: none;
  position: absolute;
  width: 100%;
}

.red-select-list li a {
  color: #7c7f85;
}

.red-select-list li a span:not(.badge--rp-connect),
.red-clickable {
  width: 20px;
  height: 20px;
  background-color: #f6f7fd;
  border-radius: 10px;
  border: 1px solid #e4e9ec;
  float: right;
  font-size: 11px;
  text-align: center;
}

.red-select-list-1 li a span:not(.badge--rp-connect) {
  border-radius: 0;
  border: none;
  height: auto;
  line-height: 1.8em;
  padding: 5px 10px;
  width: auto;
}

.red-select-list-1 li a span:first-of-type {
  margin-left: 5px;
}

.red-select-list li a span.badge--rp-connect, .red-select-list-1 li a span.badge--rp-connect {
  padding: 3px;
}

.red-select-list li a span.badge--rp-connect img, .red-select-list-1 li a span.badge--rp-connect img {
  background-color: #ffffff;
  max-width: 60px;
  padding: 5px;
}

.red-select-list-1 li:hover a {
  background-color: #FFF !important;
  color: #7c7f85 !important;
}

.red-select-list-1 li a i.fa-zoom-in {
  font-size: 18px;
}

.red-select-list-1 li a i.fa-zoom-in:hover {
  color: #BE1922;
  border: none !important;
}

.red-select-list-1 li a i {
  font-style: normal;
}

.red-select-list-1 li a i:hover {
  font-style: normal;
  border-bottom: 1px solid;
}

.red-select-list li:hover a,
.red-clickable:hover {
  background-color: #f6f7fd;
  color: #333;
}

.red-select-list li.active a,
.red-clickable.active {
  color: #be1922;
}

.list-select li.active a span,
.tab-tag-list li.active span,
.tab-contact-list li.active span:not(.badge--rp-connect) {
  background-color: #be1922;
  color: #fff;
}

.red-select-list li a span.active,
.red-clickable.active {
  background-color: #be1922;
}

.red-select-list.red-select-list-toogle {
  display: none;
}

.red-select-list.red-select-list-toogle.active {
  display: block;
}

.red-select-list li.letter-separator {
  padding: 10px 15px;
}

.red-select-list li.letter-separator span {
  background-color: #be1922;
  border-radius: 20px;
  display: table-cell;
  vertical-align: middle;
  width: 20px;
  height: 20px;
  text-align: center;
}

.red-select-list li.letter-separator span i {
  color: #fff;
  font-style: normal;
}

/** search **/

.nav.nav-tabs.nav-simple {
  border-bottom-width: 0;
}

.nav.nav-tabs.nav-border {
  border-bottom: 1px solid #e6e6e6;
  margin-bottom: 20px;
  position: 0;
}

.nav.nav-tabs.nav-border a.active {
  border-color: #bd1a1f;
  background-color: transparent;
}

.nav.nav-tabs.nav-border > li {
  border-bottom: 3px transparent solid;
  margin-right: 15px;
  margin-bottom: -3px;
  text-transform: none;
}

#publication_write .nav.nav-tabs.nav-border > li, #publication_write ul li:last-child a {
  align-items: center;
  display: flex;
}

#publication_write ul li:last-child a i { margin-right: 10px; }
#publication_write ul li:last-child a span { margin-top: 2px; }

.nav.nav-tabs.nav-border.nav-border-lg > li {
  margin-right: 15px;
}

.nav.nav-tabs.nav-simple > li {
  margin-bottom: 1px;
  margin-right: 10px;
}


.nav.nav-tabs.nav-border > li.active {
  border-color: #bd1a1f;
  background-color: transparent;
}


.nav.nav-tabs.nav-simple > li > a,
.nav.nav-tabs.nav-border > li > a {
  margin-right: 0;
  border: none;
  border-radius: 0;
  color: #7c7f85;
  padding: 15px 0;
}

.nav.nav-tabs.nav-border > li > a {
  padding: 10px;
  padding-bottom: 10px;
}

#publication_write .nav.nav-tabs.nav-border > li > a {
  padding: 10px 10px 10px 0;
}

#publication_write .nav.nav-tabs.nav-border > li > a > .fa-trash {
  cursor: pointer;
}

.nav.nav-tabs.nav-border > li:hover,
.nav.nav-tabs.nav-border > li.active:hover {
  border-color: #7c7f85;
}

.nav.nav-tabs.nav-border > li:hover a,
.nav.nav-tabs.nav-border > li.active:hover > a {
  color: #555;
}

.nav.nav-tabs.nav-simple > li > a:hover,
.nav.nav-tabs.nav-border > li > a:hover {
  border-color: transparent;
  background-color: #fff;
}

.nav.nav-tabs.nav-border > li.active > a,
.nav.nav-tabs.nav-border > li.active > a:hover,
.nav.nav-tabs.nav-border > li.active > a:focus {
  border: none;
  outline: none;
}

.file-icon .resource-wrapper,
.folder-icon .resource-wrapper,
#wizcropper-previews label {
  border: 1px solid rgba(54, 102, 206, 0.2);
}

.file-icon [class*='fa-wiz'],
.folder-icon [class*='fa-wiz'] {
  color: #4972d8;
}

.file-icon .img-responsive,
.folder-icon .img-responsive {
  width: 100%;
}

/* very small window */
@media screen and (max-width: 520px) {
  .page-header h1 {
    text-align: left;
    display: block;
  }

  .panel .panel-body .table > thead > tr > th,
  .panel .panel-body .table > tbody > tr > th,
  .panel .panel-body .table > tfoot > tr > th,
  .panel .panel-body .table > thead > tr > td,
  .panel .panel-body .table > tbody > tr > td,
  .panel .panel-body .table > tfoot > tr > td,
  .relation-contact .table > thead > tr > th,
  .relation-contact .table > tbody > tr > th,
  .relation-contact .table > tfoot > tr > th,
  .relation-contact .table > thead > tr > td,
  .relation-contact .table > tbody > tr > td,
  .relation-contact .table > tfoot > tr > td {
    padding: 5px;
  }
}

/* Activities Settings */

#activity_subjects_list li {
  cursor: pointer;
}

#activity_subjects_list li.subject-disabled {
  cursor: auto;
  font-style: italic;
  background-color: #F2DEDE;
  color: #474747;
}

#activity_subjects_list .panel-collapse {
  padding: 10px;
}

#activity_subjects_list .activity_subject_name {
  margin-left: 10px;
}

/* Activities Navbar */

a > .navbar-add-activity {
  background-color: #ffffff;
  border: 2px solid #e4e9ec;
  color: #6ccf5f;
  padding: 7px;
}

a > .navbar-add-activity:hover {
  background-color: #6ccf5f;
  color: #ffffff;
}

/* Validator Errors */

input.error, textarea.error {
  border: 1px solid red;
}

label.error {
  color: #ff0000;
}

#event_publication_write .option-selected {
  color: green;
  font-weight: bold;
}

/* Twitter Publication */

.review-social-page .fa-twitter,
.review-social-page .fa-facebook,
.review-social-page .fa-linkedin {
  font-size: 27px;
}

.review-social-page .card-bordered {
  border: 1px solid #8899a6;
}

.social-review-box .btn-success {
  float: none;
  margin-bottom: 10px;
}

.preview-social-review .js-keeper-placeholder-back {
  display: none;
}

.social-content-helper {
  min-height: 120px;
}

#js-test-hashtag .w75 {
  width: 75px !important;
}

#js-test-hashtag .w95 {
  width: 95px !important;
}

.js-hashtag > td:first-of-type:before {
  content: '\F054';
  display: inline-block;
  font-family: wizicons;
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  margin-right: 5px;
  width: 20px;
  position: relative;
  top: 3px;
}

.js-hashtag.collapsed > td:first-of-type:before {
  content: '\f07d';
}

#js-test-hashtag .js-associated-hashtag {
  padding: 8px 0;
}

#js-test-hashtag .js-associated-hashtag table {
  background: none;
  margin-bottom: 0;
}

#js-test-hashtag .js-associated-hashtag p {
  padding-left: 32px;
}

#js-test-hashtag .js-associated-hashtag table td:first-of-type {
  padding-left: 50px;
}

#js-test-hashtag a {
  color: #333;
  color: inherit;
}

#js-test-hashtag tbody span, #ritetagLegend li span {
  border-style: solid;
  border-width: 1px;
  border-left-width: 4px;
  display: inline-block;
  padding: 2px 5px;
}

#js-test-hashtag tbody span.bg-danger {
  border-color: #be1922;
}

#js-test-hashtag tbody span.bg-warning {
  border-color: #8a6d3b;
}

#js-test-hashtag tbody span.bg-info {
  border-color: #497fdc;
}

#js-test-hashtag tbody span.bg-success {
  border-color: #6ccf5f;
}

#js-test-hashtag tbody span.bg-muted, #ritetagLegend li span.bg-muted {
  background-color: #f5f5f5;
}

.twitterPost {
  margin-top: -20px;
}

.twitter-overflow {
  background: #fcc;
}

.char-counter {
  color: #6ccf5f;
}

.char-counter.char-counter-warning {
  color: #5c0002; /* Same as Twitter */
}

.char-counter.char-counter-error {
  color: #be1922;
}

/* CK Editor */

/*.cke_dialog_contents_body {
  overflow: auto;
}*/
/*
span.cke_button_icon.cke_button__source_icon {
  display: none;
}

.cke_dialog_contents_body {
  height: 100px !important;
}*/

/*span.cke_button_icon.cke_button__mediaembed_icon {
  background-position: 0 1px !important;
}

.cke_dialog_ui_select {
  display: none;
}

.cke_dialog_ui_hbox_first,
.cke_dialog_ui_hbox_child,
.cke_dialog_ui_radio_input,
.cke_dialog_ui_labeled_label,
.cke_dialog_ui_radio .cke_dialog_ui_labeled_label,
.cke_dialog_ui_radio .cke_dialog_ui_hbox_last {
  display: none !important;
}*/

/* Top navigation */

#top-nav .fa-log-out {
  font-size: 15px;
}

/* contact list */

.confim-choice-existing-list,
.confim-choice-creating-list {
  min-width: 0 !important;
  padding: 10px 20px !important;
}

#contact-list-table_wrapper .table.table-wiz.table-striped > tbody > tr:nth-child(odd) {
  background-color: #fff;
}

#contact-list-table_wrapper .table.table-wiz > tbody > tr > td {
  border-color: #F7F7F7;
  height: 22px;
  width: 1px !important;
  padding-right: 5px !important;
}

#contact-list-table_wrapper .label-default {
  background-color: #9BADD0;
}

#contact-list-table_wrapper {
  border-bottom: 1px solid #E8DEDE;
}

.tick {
  display: none;
}

.guestlist-check-icon {
  padding-bottom: 10px;
}

.guestList .panel .panel-body {
  height: 148px;
  padding: 30px 0;
  border: none;
  border-radius: 3px;
}

.guestList .panel .panel-body h1 {
  margin: 0;
}

.panel.panel-light.guestlist-selected {
  border: 3px solid #6ccf5f;
  border-radius: 5px;
}

.panel.panel-light.guestlist-not-selected {
  border: 3px solid transparent;
  border-radius: 5px;
}

.color-preview-square {
  width: 18px;
  height: 18px;
  border-radius: 4px;
  display: inline-block;
}

.thumbnail-crawled-preview img {
  height: auto;
  max-width: 100%;
}

.soft-hide {
  display: none;
}

.table-list {
  margin-bottom: 10px;
}

.table-list .row:first-child li {
  border-top: 1px solid #e7e7e7;
}

.table-list .b-pair {
  border-right: 1px solid #e7e7e7;
}

.table-list .b-pair:last-child {
  border-right: 1px solid #e7e7e7;
  border-bottom: 1px solid #e7e7e7;
}

.table-list .b-odd {
  border-left: 1px solid #e7e7e7;
}

.table-list .b-odd:first-child {
  border-right: 1px solid #e7e7e7;
  border-bottom: 1px solid #e7e7e7;
}

.table-list .b-odd:last-child {
  border-right: 1px solid #e7e7e7;
  border-bottom: 1px solid #e7e7e7;
}

.table-list li {
  position: relative;
  height: 121px;
  padding-top: 15px;
}

.table-list li .avatar {
  margin-bottom: 0;
  margin-right: 10px;
  width: 90px;
}

.table-list li .delete {
  color: #be0e26;
  display: block;
  height: 20px;
  margin-top: -10px;
  position: absolute;
  right: 10px;
  top: 50%;
}

/** API **/
#credentials-api {
  background-color: #fcf8e3;
}

#credentials-api dl {
  margin-bottom: 0;
}

/*
 * Component: section
 *
 * Use to structure the page into section, separated by a consistent space between
 * them. @TODO use that for the back office rework.
 */

.section {
  padding-top: 20px;
  padding-bottom: 20px;
}

.section-prime {
  border-bottom: 1px solid rgba(71, 159, 220, 0.5); /* = 479fdc ~ 50%*/
  margin-bottom: 20px;
}

.section > *:last-child {
  margin-bottom: 0;
}

.section-title {
  font-size: 18px;
  margin-top: 0;
  margin-bottom: 20px;
}

.section-subtitle {
  font-size: 16px;
}

.section--dashboard .panel-collapse {
  margin-top: 20px;
}

.section-aside-item .wizicon {
  margin-right: 5px;
}

/*
 * Component: card
 *
 * Same Bootstrap 4 component, replaces panel, thumbnail, etc.
 */

.card {
  background: #fff;
  /*border: 1px solid #e4eaec;*/
  margin-bottom: 30px;
}

.card-block {
  padding: 15px 15px;
}

.card-element {
  margin-bottom: 20px;
}

.card-title {
  margin: 0;
  margin-bottom: 20px;
}

.card-subtitle {
  color: #7c7f85;
  margin-top: -10px;
  margin-bottom: 20px;
}

.card-link + .card-link {
  margin-left: 15px;
}

.card-lg .card-block {
  padding: 30px 30px;
}

.card .card-img-wrapper {
  display: block;
  position: relative;
}

.card.card-newsletter-skeleton,
.card.card-pressbook-skeleton,
.card.card-page-builder {
  border: 1px solid #e4eaec;
}

.card.card-newsletter-skeleton .card-title,
.card.card-pressbook-skeleton .card-title,
.card.card-page-builder .card-title {
  font-size: 15px;
  line-height: 1.3;
  word-wrap: break-word;
}

.card-img-wrapper:before {
  bottom: 0;
  content: '';
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.card-newsletter-skeleton .card-img-wrapper:before,
.card-pressbook-skeleton .card-img-wrapper:before,
.card-page-builder .card-img-wrapper:before {
  background-color:  rgba(32, 80, 128, 0.5) /* #1F4F7F */;
}

.card-img-overlay.card-img-overlay--count {
  color: #fff;
  font-size: 55px;
  font-weight: 300;
  left: 0;
  line-height: 1;
  position: absolute;
  right: 0;
  text-align: center;
  top: 50%;
    -moz-transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.count-edition {
  display: block;
  font-size: 16px;
  font-weight: 400;
  padding-bottom: 10px;
  padding-top: 10px;
  text-transform: uppercase;
}

.card.card-newsletter-skeleton .card-img-icon,
.card.card-page-builder .card-img-icon {
  fill: #fff;
  height: 70px;
  width: 70px;
}

.card-stats {
  height: 120px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  width: 120px;
}

.card-stats .wizicon {
  fill: #f6f7fd;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.card-stats-text {
  color: #497fdc;
  display: block;
  font-size: 42px; /* 3 * font-size-base */
  padding: 30px 0; /* (height - (font-size * line-height)) / 2 */
  position: relative;
  text-align: center;
}

.card-stats-unit {
  font-size: 14px; /* 1.2 * font-size-base */
}

.card--light {
  background-color: #f6f7fd;
}

.card--light .card-stats .wizicon {
  fill: #fff;
}

.card--dashboard {
  margin-bottom: 0;
  text-align: center;
}

.card--dashboard .card-title {
  margin-bottom: 10px;
}

.card--dashboard .card-btn,
.card--dashboard .card-link {
  display: block;
  margin: 5px;
}

/*
 * Pressbooks
 */
#press_book-section .list-content-section .panel:first-child {
  border-top-color: #e4eaec;
}

#press_book-section .pressbook-section-title {
  display: inline-block;
}

.shared-item img {
  margin: 0 auto;
  text-align: center;
}

.row-infos {
  padding-bottom: 20px;
}

.col-highlight {
  padding-top: 35px;
  padding-left: 100px;
}

.display-format {
  margin-top: 30px;
  padding-top: 30px;
  border-top: 1px solid #e4eaec;
}

/*
 * Social Preview
 */

/* Apply some style to the preview keeper */
.js-keeper-editor {
  position: relative;
}

.js-keeper-editor,
.js-keeper-placeholder-back {
  overflow: hidden;
}

.js-keeper-editor {
  background: transparent;
  min-height: 200px;
  outline: none;
}

.js-keeper-placeholder-back {
  background: transparent;
  bottom: 0;
  color: transparent;
  height: auto;
  left: 0;
  position: absolute;
  padding: 10px; /* Same as textarea.form-control from Bootstrap! */
  top: 0;
  white-space: pre-wrap;
  word-wrap: break-word;
  z-index: -99;
}

.js-keeper-placeholder-back em {
  background: #fcc !important;
}

/* Hide things on load to not make things blink when loading the page */
.js-preview-card,
.js-preview-flashbag-crawl,
.js-preview-flashbag-image {
  display: none;
}

.facebook-preview .fa-facebook,
.linkedin-preview .fa-linkedin,
.twitter-preview .fa-twitter,
.yammer-preview .fa-yammer {
  float: right;
  text-align: center;
  font-size: 27px;
}

.review-social-page .fa-yammer {
  float: none;
}

.review-social-page .fa-twitter,
.twitter-preview .fa-twitter {
  color: #00aced;
}

.review-social-page .fa-facebook,
.facebook-preview .fa-facebook {
  color: #3b5998;
}

.review-social-page .fa-linkedin,
.linkedin-preview .fa-linkedin {
  color: #007bb5;
}

.facebook-preview .fa-facebook:before,
.linkedin-preview .fa-linkedin:before,
.twitter-preview .fa-twitter:before,
.yammer-preview .fa-yammer:before {
  vertical-align: inherit;
}

.yammer-preview .fa-yammer:before {
  content: "";
}

.social-preview .hashtag:hover,
.social-preview .link:hover,
.social-preview .user:hover {
  cursor: pointer;
  text-decoration: underline;
}

/**** Twitter Preview ****/
.twitter-preview {
  font-family: Helvetica, Arial, sans-serif;
  border: 1px solid #e4eaec;
  padding: 20px;
  margin-bottom: 15px;
  border-radius: 6px;
}

.twitter-preview .user-img {
  border-radius: 5px;
  width: 48px;
  float: left;
  margin-right: 10px;
}

.twitter-preview .infos-user {
  margin-bottom: 15px;
}

.twitter-preview .fullname {
  font-size: 18px;
  font-weight: bold;
}

.twitter-preview .username {
  color: #8899a6;
  font-size: 14px;
}

.twitter-preview .content-text {
  font-weight: 300;
  letter-spacing: .01em;
  line-height: 32px;
  font-size: 26px;
  margin: 0;
  padding: 10px 0;
  word-wrap: break-word;
}

.social-preview .hashtag,
.social-preview .link,
.social-preview .user {
  color: #1da1f2;
}

.twitter-preview .tweet-bar {
  padding-top: 20px;
}

.twitter-preview .shared-item {
  margin: 0 auto;
  border: 1px solid #E1E8ED;
  border-radius: .42857em;
  overflow: hidden;
}

.twitter-preview .shared-item .metas {
  font-size: 14px;
  padding: 14px 10.5px;
}

.twitter-preview .meta-title {
  display: block;
  font-size: 14px;
  font-weight: bold;
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.twitter-preview .meta-description {
  margin-top: .32333em;
  margin-bottom: 0;
}

.twitter-preview .shared-item .item-domain {
  display: block;
  color: #8899A6;
  font-size: 14px;
  margin-top: .32333em;
  margin-bottom: 0;
}

/**** Facebook Preview ****/
.facebook-preview {
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
    -moz-box-shadow: 0 0 0 1.5px rgba(0, 0, 0, .1) inset, 0 1px 1px rgba(0, 0, 0, .05);
    -webkit-box-shadow: 0 0 0 1.5px rgba(0, 0, 0, .1) inset, 0 1px 1px rgba(0, 0, 0, .05);
  box-shadow: 0 0 0 1.5px rgba(0, 0, 0, .1) inset, 0 1px 1px rgba(0, 0, 0, .05);
  padding: 15px;
  margin-bottom: 15px;
}

.facebook-preview .user-img {
  width: 40px;
  float: left;
  margin-right: 10px;
}

.facebook-preview .infos-user .username,
.facebook-preview .infos-user .posted-date {
  line-height: 20px;
  font-size: 14px;
}

.facebook-preview .infos-user .username {
  color: #3b5998;
}

.facebook-preview .infos-user .posted-date {
  color: #90949c;
  font-size: 14px;
  display: block;
}

.facebook-preview .infos-user .icon-earth {
  width: 15px;
  vertical-align: sub;
  display: inline-block;
}

.facebook-preview .username {
  color: #ccc;
}

.facebook-preview .content-text {
  font-size: 14px;
  word-wrap: break-word;
  margin: 0;
  padding: 10px 0;
  border-bottom: 0 solid #ccc;
}

.facebook-preview .content-text .hashtag,
.facebook-preview .content-text .user,
.facebook-preview .content-text .link {
  color: #3b5998;
}

.facebook-preview .icon-like {
  margin: 10px 5px 10px 0;
  width: 20px;
}

.facebook-preview .like-counter {
  color: #ccc;
  font-size: 14px;
}

.facebook-preview .bar {
  padding-top: 10px;
  margin: 0;
}

.facebook-preview .facebook-bar {
  height: 35px;
}

.facebook-preview .shared-item {
    -moz-box-shadow: 0 0 0 1.5px rgba(0, 0, 0, .1) inset, 0 1px 1px rgba(0, 0, 0, .05);
    -webkit-box-shadow: 0 0 0 1.5px rgba(0, 0, 0, .1) inset, 0 1px 1px rgba(0, 0, 0, .05);
  box-shadow: 0 0 0 1.5px rgba(0, 0, 0, .1) inset, 0 1px 1px rgba(0, 0, 0, .05);
}

.facebook-preview .shared-item .metas {
  padding: 10px 12px;
}

.facebook-preview .meta-title {
  font-family: Georgia, Lucida Grande, Tahoma, Verdana, Arial, sans-serif;
  font-size: 18px;
  font-weight: normal;
  line-height: 22px;
  margin-top: 0;
}

.facebook-preview .shared-item .metas .meta-description {
  font-size: 12px;
}

.facebook-preview .shared-item .item-domain {
  color: #90949c;
  display: block;
  font-size: 11px;
  text-transform: uppercase;
  margin-top: 10px;
}

/**** LinekdIn Preview ****/
.linkedin-preview {
  border: 1px solid #ccc;
  padding: 15px;
  margin-bottom: 15px;
  font-family: 'Source Sans Pro', Arial, Helvetica, sans-serif;
}

.linkedin-preview .infos-user {
  padding-bottom: 5px;
  padding-left: 70px;
  padding-top: 5px;
}

.linkedin-preview .user-img {
  float: left;
  margin: 5px;
  width: 48px;
}

.linkedin-preview .linkedin-card {
  padding: 10px;
  border: 1px solid #e6e6e6;
}

.linkedin-preview .infos-user .username {
  font-size: 17px;
  line-height: 20px;
}

.linkedin-preview .infos-user .posted-date {
  display: block;
  color: #727272;
}

.linkedin-preview .content-text {
  font-weight: 500;
  color: #727272;
  font-size: 15px;
  word-wrap: break-word;
  margin: 0;
  padding: 10px 0;
  border-bottom: 1px solid #e6e6e6;
}

.linkedin-preview .content-text .js-keeper-hashtag,
.linkedin-preview .content-text .js-keeper-user,
.linkedin-preview .content-text .js-keeper-link {
  color: #007bb5;
  font-weight: bold;
}

.linkedin-preview .shared-item {
  border: 1px solid #e6e6e6;
  margin: 15px 0;
}

.linkedin-preview .shared-item .metas {
  padding: 8px;
}

.linkedin-preview .meta-title {
  font-size: 15px;
  font-weight: bold;
  margin: 0;
}

.linkedin-preview .item-domain {
  color: #727272;
}

.linkedin-bar {
  height: 40px;
}

/***** Event Review *****/

.title-color-border {
  position: absolute;
  height: 2px;
  width: 130px;
  bottom: -2px;
}

.recap {
  position: relative;
  padding: 0;
  overflow: hidden;
    -webkit-background-size: cover;
  background-size: cover;
}

.text-collapse .morelink {
  text-transform: uppercase;
  display: block;
  width: 100%;
  text-align: center;
  margin-top: 30px;
}

.opac {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  background: rgba(0, 0, 0, 0.6);
}

.recap_infos {
  text-align: center;
  margin-bottom: 40px;
  color: #fff;
  position: relative;
}

.recap_infos-title {
  font-size: 1.8em;
  color: #fff;
  text-transform: uppercase;
  padding-top: 30px;
  padding-bottom: 30px;
  margin-bottom: 40px;
  border-bottom: 1px solid white;
}

.recap_infos_desc p {
  font-size: 1.3em;
  margin-top: 30px;
}

.recap_infos_timer {
  font-size: 1.1em;
  margin-top: 30px;
  margin-bottom: 30px;
}

.btn-header-event {
  color: #fff;
  padding: 15px 30px;
  text-transform: uppercase;
}

.btn-header-event:hover {
  background-color: #fff;
  transition-duration: 0.6s;
}

#hi-botman i {
  color: burlywood;
  opacity: 0;
}

#hi-botman i:first-of-type {
  color: red;
}

#hi-botman:active i, #hi-botman:hover i:first-of-type {
  opacity: 1;
}

.table.table-wiz > tbody > tr.last > td {
  border-bottom: 2px solid #dadada;
}

.custom-fields-collapse {
  margin-bottom: 20px;
}

.confirmation-list-inline {
  display: none;
}

/*
 * Navs
 */
.nav.nav--anchors2k17 {
}

.nav.nav--anchors2k17 .nav-link {
  color: #777;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.045em;
  position: relative;
  text-transform: uppercase;
}

.nav.nav--anchors2k17 .nav-link:after {
  bottom: 0;
  border-bottom: 1px solid transparent;
  content: '';
  position: absolute;
  left: 12px;
  right: 10px;
}

.nav.nav--anchors2k17 .nav-link:active,
.nav.nav--anchors2k17 .nav-link:focus,
.nav.nav--anchors2k17 .nav-link:hover,
.nav.nav--anchors2k17 .nav-item.active .nav-link {
  background-color: transparent;
  color: #be1922;
}

.nav.nav--anchors2k17 .nav-item.active .nav-link:after {
  border-bottom-color: #be1922;
}

/*
 * Position button form for mediaReviewStreamSetting Type
 */
.table-loading-row {
  background-color: #fff !important;
}

.btn.btn-primary.btn-batch-clipping {
  background-color: #f8f9fd;
  border: 1px solid #b5c8f7;
  color: #5279d9;
}

.btn.btn-primary.btn-batch-clipping:hover {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, .125);
  color: #5279d9 !important;
}

.blockchain--font-size {
  font-size: 12px;
  padding-bottom: 20px;
}

/*
 * Import Interface
 */

.text-warning-time {
  margin-bottom:20px;
}

.select-header {
  width: 100%;
}


.fa-eye.disabled {
  color: gray;
}

 /* Globe Newswire */
body .modal-content #intrado_form { padding: 8px; }


/*
 * CARD 2024
 *
 * This is a new card design for the 2024 project before it changes again.
 *
 * ¯\_(ツ)_/¯
 */
.card-2024 {
  padding: 32px;
}

.card-2024 .card-2024__icon {
  text-align: center;
}

.card-2024 .card-2024__icon svg{
  width: 76px;
  height: 88px;
}

.card-2024 .card-2024__body {
  padding: 40px 0 40px 0;
  min-height: 300px;
  height: auto;
}

.card-2024 .card-2024__body .card-2024__title {
  font-family: "Open Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  padding-bottom: 16px;
}

.card-2024 .card-2024__body .card-2024__content {
  font-family: "Open Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.card-2024 .card-2024__cta {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 10px;
  align-self: stretch;
}

.card-2024 .card-2024__cta a {
  color: #333;
  display: flex;
  padding: 8px 40px;
  align-items: center;
  gap: 8px;
  border-radius: 4px;
  border: 2px solid #333;
}
